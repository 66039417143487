<template>
  <div class="bread-container">
    <router-link class="bread-link" :to="{ name: 'home' }">
      <i class="fi fi-rr-home home-icon"></i>
    </router-link>

    <div class="bread-item-container" v-for="(item, i) in bread" :key="i">
      <i class="fi fi-rr-angle-right"></i>
      <router-link class="bread-link" :to="{ name: item.to }">
        {{ item.title }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BreadLinks',
  props: {
    bread: Array
  }
}
</script>

<style scoped lang="scss">
.bread-container {
  display: flex;
  align-items: center;

  .bread-item-container {
    display: flex;
    align-items: center;

    .fi {
      margin: 0 1.6rem;
      font-size: 2rem;
    }
  }

  a.bread-link {
    vertical-align: middle;
    color: #fff;
    font-weight: 100;
    text-decoration: none;
    padding: 0.3rem 0;

    &:not(:first) {
      border-bottom: 1px solid #fff;
    }

    &.router-link-exact-active {
      font-weight: bold;
      border-bottom: 0;

      .home-icon {
        opacity: 0.5;
      }
    }

    .home-icon {
      font-size: 3rem;
      color: #fff;
    }
  }
}

@media screen and (orientation: portrait) {
  .bread-container .bread-item-container .fi {
    margin: 0 0.4rem;
  }
}
</style>
