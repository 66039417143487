<template>
  <div id="app">
    <transition>
      <router-view v-if="watchLoginStatus" class="main-router-view" />
    </transition>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'

export default {
  created() {
    window.addEventListener('resize', this.onWindowSizeChange)
    window.addEventListener('orientationchange', this.onWindowSizeChange)

    this.watchLoginStatus()
    this.onWindowSizeChange()
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowSizeChange)
    window.removeEventListener('orientationchange', this.onWindowSizeChange)
  },
  data() {
    return {}
  },
  methods: {
    watchLoginStatus() {
      if (router.currentRoute.name === 'login') {
        return true
      }

      if (!this.userEid) {
        router.push({ name: 'login' })
        return false
      }

      return true
    },
    onWindowSizeChange() {
      let fontSize = (window.innerWidth / 1920) * 16

      fontSize *= window.innerHeight > window.innerWidth ? 1920 / 1080 : 1

      document.getElementsByTagName('html')[0].style.fontSize = fontSize + 'px'
    }
  },
  computed: {
    userEid() {
      return store.state.auth.user ? store.state.auth.user.eid : null
    }
  },
  watch: {
    userEid() {
      this.watchLoginStatus()
    }
  },
  components: {}
}
</script>

<style lang="scss">
@import '@/scss/variables.scss';

#app,
.main-router-view {
  width: 100%;
  height: 100%;
}

#app {
  background-color: $body-bg-color;
}
</style>
