<template>
  <main-layout :bread="bread">
    <div class="function-selector">
      <router-link :to="{ name: 'recognition-setting' }" class="huge-btn">
        <div>
          <div class="title">染液辨識</div>
          <div class="sub-title">Recognition</div>
        </div>
        <div>
          <div class="start">開始</div>
        </div>
      </router-link>
      <router-link :to="{ name: 'data-bank' }" class="huge-btn">
        <div>
          <div class="title">資料庫</div>
          <div class="sub-title">Data Bank</div>
        </div>
        <div>
          <div class="start">開始</div>
        </div>
      </router-link>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '@/layout/MainLayout.vue'

export default {
  name: 'HomeView',
  data() {
    return {
      bread: []
    }
  },
  components: {
    MainLayout
  }
}
</script>

<style lang="scss" scoped>
.function-selector {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .huge-btn {
    width: 33vw;
    height: 66vh;
    max-height: 33vw;
    border-radius: 2rem;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0 4%;
    text-decoration: none;

    &:nth-child(1) {
      background-color: #4527a0;
      box-shadow: 0 1rem 0.4rem 0rem #010170;
    }

    &:nth-child(2) {
      background-color: #01579b;
      box-shadow: 0 1rem 0.4rem 0rem #002f6c;
    }

    & > *:nth-child(1) {
      height: 74%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    & > *:nth-child(2) {
      height: 26%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-top: 2px solid #000;
    }

    .title {
      font-size: 7rem;
    }

    .sub-title {
      font-size: 4.4rem;
    }

    .start {
      font-size: 3.5rem;
    }
  }
}

@media screen and (orientation: portrait) {
  .function-selector {
    flex-direction: column;
    justify-content: space-around;

    .huge-btn {
      width: 66vw;
      height: 33vh;
      max-height: 66vw;
    }
  }
}
</style>
