<template>
  <div class="main-layout">
    <header-section class="header-section" :bread="bread"></header-section>
    <slot></slot>
  </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue'

export default {
  name: 'MainLayout',
  components: {
    HeaderSection
  },
  props: {
    bread: Array
  }
}
</script>

<style scoped lang="scss">
.main-layout {
  width: 100vw;
  height: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .header-section {
    width: 100%;
    height: 7rem;
    overflow: hidden;
  }

  & > *:nth-child(2) {
    width: 100%;
    height: calc(100% - 7rem);
    overflow: hidden;
  }
}
</style>
