import axios from '@/libs/axios'
import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('user'))
  },
  mutations: {
    login: function (state, { eid, name }) {
      state.user = {
        eid: eid,
        name: name
      }

      localStorage.setItem('user', JSON.stringify(state.user))
    },
    logout: function (state) {
      state.user = {
        eid: null,
        name: null
      }

      localStorage.removeItem('user')
    }
  },
  actions: {
    login(ctx, params) {
      const formData = new FormData()

      if (typeof params === 'object') {
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key])
        })
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/api/login', formData)
          .then((response) => {
            if (response.data.result) {
              ctx.commit('login', {
                eid: response.data.user.eid,
                name: response.data.user.name
              })
              resolve(response)
              return
            }

            if (response.data.errorMessage) {
              Swal.fire({
                title: response.data.errorMessage,
                confirmButtonText: '確定'
              })
            }

            reject(response.data.errorMessage)
          })
          .catch((error) => reject(error))
      })
    },
    logout(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/logout')
          .then((response) => {
            if (response.data.result) {
              ctx.commit('logout')
              resolve(response)
              return
            }

            reject(response.data.errorMessage)
          })
          .catch((error) => reject(error))
      })
    }
  }
}
