import axios from '@/libs/axios'
import router from '@/router'
import Swal from 'sweetalert2'

export default {
  namespaced: true,
  state: {
    settings: {
      sampleNumber: '',
      batchNumber: '',
      testStep: ''
    },
    capturePhoto: {
      base64: null,
      name: '',
      time: null
    },
    identifyPhoto: null,
    submitReturn: {
      result: '',
      down: '',
      'up-left': '',
      'up-right': ''
    }
  },
  mutations: {
    saveSettings: function (state, { sampleNumber, batchNumber, testStep }) {
      state.settings.sampleNumber = sampleNumber
      state.settings.batchNumber = batchNumber
      state.settings.testStep = testStep
    },
    capturePhoto: function (state, base64) {
      let date = new Date()
      let time = new Date().toISOString()
      let name = new Date(date.getTime() - date.getTimezoneOffset())
        .toISOString()
        .substring(0, 19)
        .replaceAll('-', '')
        .replaceAll(':', '')
        .replace('T', '_')

      name +=
        '_' +
        state.settings.sampleNumber +
        '_' +
        state.settings.batchNumber +
        '_' +
        state.settings.testStep

      state.capturePhoto.base64 = base64
      state.capturePhoto.name = name
      state.capturePhoto.time = time
    },
    identifyPhoto: function (state, data) {
      state.identifyPhoto = data.base64
      state.submitReturn = data.submitReturn
    }
  },
  actions: {
    submitPhoto(ctx, params) {
      const formData = new FormData()

      if (typeof params === 'object') {
        Object.keys(params).forEach((key) => {
          formData.append(key, params[key])
        })
      }

      return new Promise((resolve, reject) => {
        axios
          .post('/api/recognition', formData)
          .then((response) => {
            if (response.data.result) {
              ctx.commit('identifyPhoto', {
                base64: response.data.img,
                submitReturn: response.data.submitReturn
              })

              resolve(response)
              return
            }

            if (response.data.errorMessage) {
              if (response.data.errorMessage === 'LOGIN_REQUIRED') {
                Swal.fire({
                  title: '登入逾時，請重新登入',
                  confirmButtonText: '確定'
                })

                router.push({ name: 'login' })
                reject(response.data.errorMessage)
                return
              }

              Swal.fire({
                title: response.data.errorMessage,
                confirmButtonText: '確定'
              })

              reject(response.data.errorMessage)
            }
          })
          .catch((error) => reject(error))
      })
    },
    upload(ctx, userEid) {
      function dataURItoBlob(dataURI) {
        var byteString
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1])
        else byteString = unescape(dataURI.split(',')[1])

        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        var ia = new Uint8Array(byteString.length)
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i)
        }

        return new Blob([ia], { type: mimeString })
      }

      const params = {
        sampleNumber: ctx.state.settings.sampleNumber,
        batchNumber: ctx.state.settings.batchNumber,
        testStep: ctx.state.settings.testStep,
        capturePhotoName: ctx.state.capturePhoto.name,
        capturePhoto: dataURItoBlob(ctx.state.capturePhoto.base64),
        captureTime: ctx.state.capturePhoto.time,
        identifyPhoto: dataURItoBlob(ctx.state.identifyPhoto),
        submitReturn: JSON.stringify(ctx.state.submitReturn),
        userEid: userEid
      }

      const formData = new FormData()

      Object.keys(params).forEach((key) => {
        formData.append(key, params[key])
      })

      return new Promise((resolve, reject) => {
        axios
          .post('/api/recognition/upload', formData)
          .then((response) => {
            if (response.data.result) {
              resolve(response)
              return
            }

            if (response.data.errorMessage) {
              if (response.data.errorMessage === 'LOGIN_REQUIRED') {
                Swal.fire({
                  title: '登入逾時，請重新登入',
                  confirmButtonText: '確定'
                })

                router.push({ name: 'login' })
                reject(response.data.errorMessage)
                return
              }

              Swal.fire({
                title: response.data.errorMessage,
                confirmButtonText: '確定'
              })

              reject(response.data.errorMessage)
            }
          })
          .catch((error) => reject(error))
      })
    }
  }
}
