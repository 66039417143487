import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import Swal from 'sweetalert2'

const api = {
  /*
   * HTTP GET
   */
  get(url, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { params: queryParams })
        .then((response) => {
          if (!response.data.result) {
            this.errorMessageHandler(response.data.errorMessage)
          }

          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
  /*
   * HTTP POST
   */
  post(url, queryParams) {
    const formData = new FormData()

    if (typeof queryParams === 'object') {
      Object.keys(queryParams).forEach((key) => {
        formData.append(key, queryParams[key])
      })
    }

    return new Promise((resolve, reject) => {
      axios
        .post(url, formData)
        .then((response) => {
          if (!response.data.result) {
            this.errorMessageHandler(response.data.errorMessage)
          }

          resolve(response.data)
        })
        .catch((error) => reject(error))
    })
  },
  errorMessageHandler(errorMessage) {
    if (errorMessage === 'RELOAD_REQUIRED') {
      // 此頁面要求必須重新載入
      if (router.currentRoute.meta.requireLogin) {
        // 此頁面要求必須登入
        router.go()
      }

      return
    }

    if (errorMessage === 'LOGIN_REQUIRED') {
      // 未登入狀態
      if (router.currentRoute.name !== 'login') {
        // 此頁面要求必須登入
        Swal.fire({
          title: '請先登入!',
          text: '尚未登入或登入逾時',
          icon: 'error',
          confirmButtonText: '確定'
        })

        router.push('login')
      }

      return
    }

    Swal.fire({
      title: '錯誤',
      text: errorMessage,
      icon: 'error',
      confirmButtonText: '確定'
    })
  }
}

Vue.prototype.$api = api

export default api
