import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  //登入頁
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  //主頁(首頁)
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  //辨識設定
  {
    path: '/recognition-setting',
    name: 'recognition-setting',
    component: () => import('../views/RecognitionSettingView.vue')
  },
  //辨戠相機
  {
    path: '/recognition-camera',
    name: 'recognition-camera',
    component: () => import('../views/RecognitionCameraView.vue')
  },
  //辨戠
  {
    path: '/recognition-identify',
    name: 'recognition-identify',
    component: () => import('../views/RecognitionIdentifyView.vue')
  },
  //資料庫
  {
    path: '/data-bank',
    name: 'data-bank',
    component: () => import('../views/DataBankView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
