<template>
  <div class="header-section">
    <bread-links :bread="bread" />
    <div class="user-name-area">
      <div class="user-name mx-3">
        {{ userName }}
        <i class="fi fi-rr-user mx-3"></i>
      </div>
      <div class="ml-3">
        <button class="btn btn-white" @click="onLogoutClicked">登出</button>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Swal from 'sweetalert2'
import BreadLinks from '@/components/BreadLinks.vue'

export default {
  name: 'HeaderSection',
  methods: {
    onLogoutClicked() {
      store.dispatch('auth/logout').then(
        () => {
          Swal.fire({
            title: '您已經登出',
            confirmButtonText: '確定'
          })
        },
        (error) => {
          console.log('ERROR', error)
        }
      )
    }
  },
  computed: {
    userName() {
      return store.state.auth.user ? store.state.auth.user.name : ''
    }
  },
  components: {
    BreadLinks
  },
  props: {
    bread: Array
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/variables.scss';

.header-section {
  background-color: $danger-color;
  color: #fff;
  font-size: 2.4rem;
  line-height: 2rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  i.fi {
    font-size: 3rem;
    vertical-align: middle;

    &.small {
      font-size: 2rem;
    }
  }

  .btn.btn-white {
    background-color: #fff;
    color: $danger-color;
    box-shadow: 0 6px 6px $danger-shadow-color;
    font-size: 2rem;
    font-weight: bold;
    padding: 0;
    width: 12rem;
    height: 4rem;
    border-radius: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-name-area {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .user-name {
    display: flex;
    align-items: center;
    border-right: 1px solid rgba(255 255 255/70%);
    white-space: nowrap;
  }
}
</style>
